<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Ciudades</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Ciudades</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_ciudad_export"
                        v-if="$store.getters.can('admin.ciudades.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modal-form-ciudades"
                        @click="abrirModal('Crear', null)"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Ciudad
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.ciudad"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Código DANE
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.cod_dane"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Departamento
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.departamento"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="departamento in listasForms.departamentos"
                            :key="departamento.id"
                            :value="departamento.id"
                          >
                            {{ departamento.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ciudad in ciudades.data" :key="ciudad.id">
                      <td>{{ ciudad.nombre }}</td>
                      <td>{{ ciudad.cod_dane }}</td>
                      <td>{{ ciudad.departamento.descripcion }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            ciudad.estado == 1
                              ? 'badge-success'
                              : 'badge-danger',
                          ]"
                        >
                          {{ ciudad.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-form-ciudades"
                            @click="abrirModal('Editar', ciudad.id)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <!-- Funcionalidad oculta por solicitud del cliente 
                            los métodos no fueron quitados -->
                          <!-- <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            @click="destroy(ciudad.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="ciudades.total">
                  <p>
                    Mostrando del <b>{{ ciudades.from }}</b> al
                    <b>{{ ciudades.to }}</b> de un total:
                    <b>{{ ciudades.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="ciudades"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-ciudades">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="departamento_id">Departamento</label>
              <select
                id="departamento_id"
                class="form-control form-control-sm"
                v-model="objCiudad.departamento_id"
                :class="
                  $v.objCiudad.departamento_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              >
                <option value="" disabled selected>Seleccione...</option>
                <option
                  v-for="departamento in listasForms.departamentos"
                  :key="departamento.id"
                  :value="departamento.id"
                >
                  {{ departamento.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.objCiudad.departamento_id.required">
                Seleccione un departamento
              </div>
            </div>
            <div class="form-group">
              <label for="nombre">Ciudad</label>
              <input
                type="text"
                class="form-control form-control-sm"
                id="nombre"
                placeholder="Ciudad"
                v-model="objCiudad.nombre"
                :class="
                  $v.objCiudad.nombre.$invalid ? 'is-invalid' : 'is-valid'
                "
                :readonly="!objCiudad.departamento_id"
                @blur="validaCiudad"
              />
              <div class="error" v-if="!$v.objCiudad.nombre.required">
                Ingrese una ciudad
              </div>
            </div>
            <div class="form-group">
              <label for="ciudad_id" class="col-md-6">Ciudad Google Maps</label>
              <gmap-autocomplete
                class="form-control form-control-sm"
                @place_changed="setPlace"
                :options="{
                  fields: [
                    'geometry',
                    'formatted_address',
                    'address_components',
                  ],
                }"
                :value="addressInput"
              >
                >
                <template v-slot:input="slotProps">
                  <v-text-field
                    outlined
                    prepend-inner-icon="place"
                    placeholder="Location Of Event"
                    ref="input"                    
                    v-on:listeners="slotProps.listeners"
                    v-on:attrs="slotProps.attrs"
                  >
                  </v-text-field>
                </template>
              </gmap-autocomplete>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label for="latitud">Latitud</label>
                <input
                  type="double"
                  class="form-control form-control-sm"
                  id="latitud"
                  placeholder="Latitud"
                  v-model="objCiudad.latitud"
                  disabled
                  :class="
                    $v.objCiudad.latitud.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
                <div class="error" v-if="!$v.objCiudad.latitud.required">
                  Ingrese latitud
                </div>
              </div>
              <div class="col-md-6">
                <label for="latitud">Longitud</label>
                <input
                  type="double"
                  class="form-control form-control-sm"
                  id="longitud"
                  placeholder="Longitud"
                  v-model="objCiudad.longitud"
                  disabled
                  :class="
                    $v.objCiudad.longitud.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
                <div class="error" v-if="!$v.objCiudad.longitud.required">
                  Ingrese longitud
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="cod_dane">Código DANE</label>
              <input
                type="text"
                class="form-control form-control-sm"
                id="cod_dane"
                placeholder="Código DANE"
                v-model="objCiudad.cod_dane"
                :class="
                  $v.objCiudad.cod_dane.$invalid ? 'is-invalid' : 'is-valid'
                "
              />
              <div class="error" v-if="!$v.objCiudad.cod_dane.required">
                Ingrese un código DANE
              </div>
            </div>
            <div class="form-group">
              <label for="estado">Estado</label>
              <select
                id="estado"
                class="form-control form-control-sm"
                v-model="objCiudad.estado"
                :class="
                  $v.objCiudad.estado.$invalid ? 'is-invalid' : 'is-valid'
                "
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in listasForms.estados"
                  :key="estado.id"
                  :value="estado.id"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.objCiudad.estado.required">
                Seleccione un estado
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.objCiudad.$invalid"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <CiudadExport ref="CiudadExport" />
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength, between } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import CiudadExport from "./CiudadExport";
import { numberFormat } from "highcharts";

export default {
  name: "CiudadIndex",
  components: {
    pagination,
    Loading,
    CiudadExport,
  },
  data() {
    return {
      filtros: {
        ciudad: null,
        cod_dane: null,
        latitud: null,
        longitud: null,
      },
      ciudades: {},
      objCiudad: {},
      listasForms: {
        departamentos: [],
        estados: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      cargando: false,
      addressInput: "",
      page: ""
    };
  },
  validations: {
    objCiudad: {
      departamento_id: {
        required,
      },
      nombre: {
        required,
      },
      latitud: {
        required,
        minLength: minLength(5),
      },
      longitud: {
        required,
        minLength: minLength(5),
      },
      cod_dane: {
        required,
      },
      estado: {
        required,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      this.page = page;
      axios
        .get("/api/admin/ciudades?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.ciudades = response.data;
        });
    },

    getDepartamentos() {
      axios.get("/api/admin/departamentos/lista").then((response) => {
        this.listasForms.departamentos = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    abrirModal(accion, idCiudad) {
      this.getEstados();
      this.modal.accion = accion;
      this.modal.title = accion + " Ciudad";
      if (accion == "Editar") {
        this.show(idCiudad);
      } else {
        this.objCiudad = {};
      }
    },

    setPlace(place) {      
      const longitud = this.getCentro(place.geometry.viewport.Ra);
      const latitud = this.getCentro(place.geometry.viewport.Ab);
      this.objCiudad.latitud = latitud;
      this.objCiudad.longitud = longitud;
    },

    getCentro(coordenadas) {
      return (coordenadas.g + coordenadas.h) / 2;
    },

    validaCiudad() {
      let params = {
        departamento: this.objCiudad.departamento_id,
        ciudad: this.objCiudad.nombre,
      };
      axios
        .get("/api/admin/ciudades/lista", {
          params,
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.objCiudad.nombre = null;
            this.$swal({
              icon: "error",
              title: "La ciudad digitada ya está creada...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
    },

    save() {
      if (!this.$v.objCiudad.$invalid) {
        this.cargando = true;
        axios
          .post("/api/admin/ciudades", this.objCiudad)
          .then((response) => {
            this.$refs.closeModal.click();            
            this.objCiudad = {};
            this.getIndex(this.page);
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vualeva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    show(idCiudad) {
      axios.get("/api/admin/ciudades/show/" + idCiudad).then((response) => {
        this.objCiudad = response.data;
      });
    },

    destroy(idCiudad) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/admin/ciudades/" + idCiudad).then((response) => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino el item exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getEstados();
    this.getDepartamentos();
  },
};
</script>

<style>
.pac-container {
  z-index: 1051 !important;
}
</style>